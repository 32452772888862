<template>
    <div class="not-found">
        <img src="../assets/404.gif" alt="not found">
    </div>

</template>

<script>

export default {
    name: "404",
    components:{
    },
}
</script>

<style scoped>
    .not-found{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .not-found img{
        width: 100%;
        height: 100%;
    }

</style>
